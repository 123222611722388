import React from 'react';
import "antd/dist/antd.css";

import MainRouter from "../router";

const Novaz = () => (
  // <I18nextProvider i18n={i18n}>
    <MainRouter />
  // </I18nextProvider>
);

export default Novaz;